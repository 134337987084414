import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import {i18n} from "@/common/localization";
import {faro} from "@grafana/faro-web-sdk";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Dashboard',
        component: () => import('@/app/dashboard/AppDashboard.vue'),
        meta: {
            main: '',
            title: 'pageHeaders.dashboard'
        },
    },
    {
        path: '/catalog',
        name: 'Catalog',
        component: () => import('@/app/catalog/AppCatalog.vue'),
        children: [
            {
                path: 'info/:id',
                name: 'CatalogProductInfoDialog',
                component: () => import('@/app/catalog/desktop/DesktopCatalogInfoDialog.vue'),
                props: true
            }
        ],
        meta: {
            main: "Catalog",
            title: 'pageHeaders.catalog'
        },
    },
    {
        path: '/catalog/:id',
        name: 'CatalogProduct',
        component: () => import('@/app/catalog/mobile/MobileCatalogProduct.vue'),
        meta: {
            main: "Catalog",
            title: 'pageHeaders.catalog'
        },
    },
    {
        path: '/orders',
        name: 'Orders',
        component: () => import('@/app/orders/list/OrderList.vue'),
        meta: {
            main: "Orders",
            title: 'pageHeaders.orders'
        },
    },
    {
        path: '/orders/:id',
        name: 'Order',
        component: () => import('@/app/orders/AppOrder.vue'),
        meta: {
            main: "Orders",
            title: 'pageHeaders.orders'
        },
    },
    {
        path: '/orders/:id/invoice',
        name: 'OrderInvoice',
        component: () => import('@/app/orders/desktop/DesktopOrderInvoice.vue'),
        meta: {
            main: "Orders",
            title: 'pageHeaders.orders'
        },
    },
    {
        path: '/cart',
        name: 'Cart',
        component: () => import('@/app/cart/AppCart.vue'),
        meta: {
            main: "Cart",
            title: 'pageHeaders.cart'
        },
    },
    {
        path: '/cart/checkout',
        name: 'CartCheckout',
        component: () => import('@/app/cart/CartCheckout.vue'),
        meta: {
            main: "Cart",
            title: 'pageHeaders.cart'
        },
    },
    {
        path: '/finance',
        name: 'Finance',
        component: () => import('@/app/finance/AppFinance.vue'),
        meta: {
            main: "Finance",
            title: 'pageHeaders.finance'
        },
    },
    {
        path: '/invoices',
        name: 'Invoices',
        component: () => import('@/app/invoices/AppInvoices.vue'),
        meta: {
            main: "Finance",
            title: 'pageHeaders.finance'
        },
    },
    {
        path: '/invoices/:id',
        name: 'Invoice',
        component: () => import('@/app/invoices/AppInvoice.vue'),
        meta: {
            main: "Finance",
            title: 'pageHeaders.finance'
        },
    },
    {
        path: '/account',
        name: 'Account',
        component: () => import('@/app/account/AppAccount.vue'),
        meta: {
            main: "Account",
            title: 'pageHeaders.account'
        },
    },
    {
        path: '/plans',
        name: 'Plans',
        component: () => import('@/app/plan/PlansList.vue'),
        meta: {
            main: "Plans",
            title: 'pageHeaders.plans'
        },
    },
    {
        path: '/news/:id',
        name: 'News',
        component: () => import('@/app/news/AppNewsPage.vue'),
        meta: {
            main: "News",
            title: 'pageHeaders.news'
        },
    },
    {
        path: '/return_requests',
        name: 'ReturnRequests',
        component: () => import('@/app/returnRequest/ReturnRequestList.vue'),
        meta: {
            main: "ReturnRequests",
            title: 'pageHeaders.returnRequests'
        },
    },
    {
        path: '/return_requests/:id',
        name: 'ReturnRequest',
        component: () => import('@/app/returnRequest/ReturnRequestPage.vue'),
        meta: {
            main: "ReturnRequests",
            title: 'pageHeaders.returnRequests'
        },
    },
    {
        path: "/:pathMatch(.*)*",
        name: "PageNotFound",
        component: () => import("@/app/AppPageNotFound.vue"),
        meta: {
            main: "",
            title: 'pageHeaders.notFound'
        },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

const DEFAULT_TITLE = 'Lidskoe';
router.afterEach((to) => {
    document.title = i18n.global.t(to.meta.title as string) || DEFAULT_TITLE;
    if (process.env.NODE_ENV !== "development") {
        faro.api.setView({name: to.name?.toLocaleString() ?? 'default'});
    }
});

export default router
