import {Centrifuge} from "centrifuge";
import {TOKEN_LOCAL_STORAGE} from "@/common/http";

class WebSocketNotification {
    private instance: Centrifuge | null = null;

    public get ws(): Centrifuge {
        return this.instance != null ? this.instance : this.init();
    }

    private init() {
        if (!process.env.VUE_APP_WSS_URL) {
            throw new Error("VUE_APP_WSS_URL is not specified");
        }

        const centrifuge = new Centrifuge(process.env.VUE_APP_WSS_URL, {
            token: localStorage.getItem(TOKEN_LOCAL_STORAGE)
        });

        centrifuge.on('connecting', function (ctx) {
            console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
        }).on('connected', function (ctx) {
            console.log(`connected over ${ctx.transport}`);
        }).on('disconnected', function (ctx) {
            console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
        }).connect();

        this.instance = centrifuge;
        return centrifuge;
    }
}

export const wsNotification = new WebSocketNotification();