import {getWebInstrumentations, initializeFaro} from "@grafana/faro-web-sdk";
import {TracingInstrumentation} from '@grafana/faro-web-tracing';
import type {Faro} from "@grafana/faro-core";

export const initFaro = (): Faro | null => {
    if (process.env.NODE_ENV === "development") {
        return null;
    }
    return initializeFaro({
        url: 'https://faro-collector-prod-eu-west-2.grafana.net/collect/ca45856ed5df87ae7e88780e3a160caf',
        app: {
            name: 'WebApp',
            version: '2.0.0',
            environment: process.env.NODE_ENV
        },

        instrumentations: [
            ...getWebInstrumentations(),
            new TracingInstrumentation(),
        ],
    });
}
